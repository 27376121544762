import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import PageHelmet from "../../components/PageHelmet";
import config from "../../config";
import {MyLink} from "../../components/MyLink";
import {LINKS} from "../../helpers/constants";
import {getNameInitials} from "../../helpers/utils";
import Avatar from "../../components/UI/Avatar";
import InvestProgressBar from "../../components/InvestProgressBar";
import MiniFaqs, {MINIFAQ_SOURCES} from "../../containers/MiniFaqs";
import {getFundraisingDates, getFundraisingStats, getParticipatingFounders} from "../../store/actions/appAction";
import Countdown from '../../components/Countdown';
import Linker from '../../components/Linker';
import Preloader from '../../components/Preloader';
import qs from "qs";
import {getReferralCodeFromCookie, saveReferralCodeToCookie} from "../../helpers/referral";
import {useLocation, useNavigate} from "react-router-dom";
import './index.scss';

const SEO = {
  title: 'Scramble｜Join Investment Round Today!',
  description: "Register on the platform and take part in current startups' funding round. Enjoy attractive returns and a 6-24-months term on your startup loans. Invest now!",
  url: 'https://scrambleup.com/round',
  image: null
}

const CONS = [
  {
    title: 'Above-average\n annual returns',
    desc: 'from 12% to 18% per\n annum in euro',
    link: 'https://scrambleup.com/product?step=3'
  },
  {
    title: 'High degree of\n protection',
    desc: 'Three-stage risk\n protection mechanics',
    link: 'https://scrambleup.com/product?step=4'
  },
  {
    title: 'Carefully-vetted\n companies',
    desc: 'A new group of\n companies every month',
    link: 'https://scrambleup.com/how-it-works?step=2'
  }
];

const RoundPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [refCode, setRefCode] = useState('');
  const { fundraisingDates, fundraisingStats, participatingFounders } = useSelector(state => state.appStore);
  const { loading, data: fundraising } = fundraisingDates;
  const { data: founders } = participatingFounders;

  useEffect(() => {
    dispatch(getFundraisingDates());
    dispatch(getFundraisingStats());
    dispatch(getParticipatingFounders());
  }, []);

  useEffect(() => {
    const { ref } = qs.parse(location.search.replace('?', ''));
    if (ref) {
      saveReferralCodeToCookie(ref);
      setRefCode(ref);
    } else {
      const code = getReferralCodeFromCookie();
      if (code) {
        navigate(location.pathname + `?ref=${code}`, { replace: true });
        setRefCode(code);
      }
    }
  }, []);

  const signUpLink = `${LINKS.signUp}${refCode ? `?ref=${refCode}`: ''}`;

  return (
    <>
      <PageHelmet
        title={SEO.title}
        description={SEO.description}
        image={SEO.image}
        url={SEO.url || config.baseURL + `/round`}
      />

      <div className="round_page">
        {(loading || !fundraising)
          ? <Preloader className="round_page__preloader" />
          : (
            <div className="round_page__inner">
              <div className="container">
                <section className="round_page__main">
                  <div className="round_page__main_inner">
                    <h1 className="round_page__title f-56-64 f-500">
                      {fundraising.is_active
                        ? <span>Join the <span className="nowrap half_colored">{moment(fundraising.start_date).format('MMMM')}</span> investment round!</span>
                        : <span>The next investment round starts on <span className="nowrap half_colored">{moment(fundraising.start_date).format('D MMMM')}</span></span>
                      }
                    </h1>
                    <p className="round_page__desc f-24-36 f-400">
                      {fundraising.is_active
                        ? "Sign up to Scramble and take part in the funding round! Investing in secured and high-yield loans to innovative businesses has never been easier."
                        : "Be the first to know the founders batch of the next round. Sign up so you don't miss any important information."
                      }
                    </p>

                    {fundraising.is_active && (
                      <div className="round_page__countdown">
                        <span className="f-18-24 c-grey">Time left:</span>
                        <div>
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 32C24.7977 32 32 24.7822 32 16C32 7.21781 24.7977 0 16.0155 0C15.0707 0 14.544 0.557599 14.544 1.48693V7.20232C14.544 7.99226 15.0862 8.59632 15.8606 8.59632C16.6505 8.59632 17.1926 7.99226 17.1926 7.20232V3.17522C23.7754 3.77928 28.8403 9.26234 28.8403 16C28.8403 23.1094 23.1404 28.8403 16 28.8403C8.85963 28.8403 3.14424 23.1094 3.14424 16C3.15973 12.9177 4.24395 10.0833 6.05615 7.88383C6.62924 7.0939 6.72217 6.2575 6.07164 5.60697C5.4211 4.94095 4.36786 5.01839 3.68635 5.90126C1.394 8.65828 0 12.1897 0 16C0 24.7822 7.20232 32 16 32ZM18.4937 18.3698C19.7638 17.0378 19.5005 15.2101 17.9826 14.1723L10.0833 8.73572C9.16941 8.11617 8.27106 9.03001 8.89061 9.92836L14.3272 17.8277C15.365 19.3456 17.1772 19.6399 18.4937 18.3698Z" fill="black"/>
                          </svg>
                          <Countdown endDate={fundraising.end_date} onFinish={() => dispatch(getFundraisingDates())} className="f-38-48 f-500" />
                        </div>
                      </div>
                    )}

                    <MyLink
                      href={signUpLink}
                      isExternal
                      className="round_page__investor_link scr-button yellow one"
                    >
                    <span className="f-18-24 f-500">
                      {fundraising.is_active ? "Join the investment round" : "Sign up now"}
                    </span>
                    </MyLink>
                  </div>
                </section>

                {fundraising.is_active && (
                  <section className="round_page__founders">
                    <h2 className="f-38-48 f-500">Founders batch</h2>
                    <div className="round_page__founders_list">
                      {founders?.list.map(founder => (
                        <div className="round_page__founders_card" key={founder.id}>
                          <div className="round_page__founders_card_avatars">
                            {founder.co_founders.map(cFounder => (
                              <Avatar
                                key={cFounder.id}
                                size={70}
                                alt={getNameInitials(cFounder.first_name, cFounder.last_name)}
                                src={cFounder.photo?.file}
                                nameInitials={getNameInitials(cFounder.first_name, cFounder.last_name)}
                                className="round_page__founders_card_avatar"
                              />
                            ))}
                          </div>
                          <h3 className="f-26-32 f-500">{founder.company.name}</h3>
                          {founder.company.site && (
                            <Linker
                              value={founder.company.site}
                              className="round_page__founders_card_site f-18-24 f-400 c-grey tl"
                            />
                          )}
                          <ul className="round_page__founders_card_sections f-18-24 f-400">
                            {founder.themes.map(theme => (
                              <li key={theme.id}>{theme.title}</li>
                            ))}
                          </ul>
                        </div>
                      ))}

                      <div className="round_page__founders_card">
                        <div className="round_page__founders_card_content">
                          <div>Sign up to meet other <br/>amazing founders of the round</div>
                          <a href={LINKS.signUp + refCode ? `?ref=${refCode}`: ''} isExternal className="scr-button white">
                            <span className="f-18-24 f-500">Create account</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                <section className="round_page__fundraising">
                  <h2 className="f-38-48 f-500">Fundraising progress</h2>
                  <InvestProgressBar stats={fundraisingStats} />
                </section>

                <section className="round_page__why">
                  <h2 className="f-38-48 f-500">Why Scramble?</h2>
                  <div className="round_page__why_circles">
                    {CONS.map(item => (
                      <div className="round_page__why_circle">
                        <div className="round_page__why_circle_content">
                          <h3 className="f-26-32 f-500">{item.title}</h3>
                          <p className="f-18-24 f-400">{item.desc}</p>
                          <MyLink
                            href={item.link}
                            className="c-grey"
                          >
                            Learn more
                          </MyLink>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="round_page__faq">
                  <MiniFaqs source={MINIFAQ_SOURCES.round} />
                </section>

                <MyLink href={signUpLink} isExternal className="round_page__investor_link scr-button yellow two">
                  <span className="f-18-24 f-500">{fundraising.is_active ? "Join the investment round" : "Sign up now"}</span>
                </MyLink>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default RoundPage;