import React from 'react';

import './linkedIn.scss';

function LinkedIn() {
  return (
    <a
      className="socialButton_linkedIn"
      href="https://www.linkedin.com/company/scrambleup"
      target="_blank"
      rel="noreferrer"
    >
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.65637 5.85022H0.731445V16.0874H3.65637V5.85022Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.38738 2.19293C4.36207 0.948122 3.54048 0 2.20635 0C0.872211 0 0 0.948122 0 2.19293C0 3.41196 0.846432 4.38738 2.15572 4.38738H2.18064C3.54048 4.38738 4.38738 3.41196 4.38738 2.19293Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8181 9.94248C16.8181 6.65082 15.0456 5.11884 12.6813 5.11884C10.7738 5.11884 9.91974 6.15899 9.44288 6.88867V5.37076H5.84961C5.89696 6.37635 5.84961 16.0873 5.84961 16.0873H9.44288V10.1023C9.44288 9.78201 9.4662 9.46252 9.5612 9.2332C9.82086 8.59335 10.4121 7.93088 11.4047 7.93088C12.7053 7.93088 13.2252 8.91356 13.2252 10.3537V16.087H16.8179L16.8181 9.94248Z" fill="black" />
      </svg>
    </a>
  );
}

export default LinkedIn;
