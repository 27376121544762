import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config';

export const MyLink = ({isExternal, href, newWindow = true, children, ...other}) => {
  const linkOptions = {};
  if (isExternal && newWindow) {
    linkOptions.target = '_blank';
    linkOptions.rel = 'noopener noreferrer';
  }
  return isExternal
    ? <a href={href} {...linkOptions} {...other}>{children}</a>
    : <Link to={href.replace(config.baseURL, '')} {...other}>{children}</Link>
};