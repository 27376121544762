import React from 'react';
import * as classnames from 'classnames';
import {ReactComponent as IconKey} from "../../assets/images/icon_key.svg";
import {MyLink} from "../MyLink";
import './index.scss';


const InvestProgressBar = ({ stats }) => {
  let percent = 0;
  if (stats && stats.total_goal !== 0) {
    percent = Math.round((stats.currently_allocated * 100) / stats.total_goal);
  }

  return (
    <div className="invest-progress-bar">
      <div className="invest-progress-bar__top">
        <div className="invest-progress-bar__top-block">
          <div className="f-16-24 f-400 c-grey">
            Allocated to the round
          </div>
          <MyLink href="#">
              <span className="f-38-48 f-500">***</span>
              <IconKey />
          </MyLink>
        </div>
        <div className="invest-progress-bar__top-block">
          <div className="f-16-24 f-400 c-grey">
            Founders fundraising goal
          </div>
          <MyLink href="#">
            <span className="f-38-48 f-500">*****</span>
            <IconKey />
          </MyLink>
        </div>
      </div>
      <div
        className={classnames('invest-progress-bar__bar', !percent && 'zero')}
      >
        {percent <= 90 && (<span className="full f-14 f-400">100%</span>)}
        <div
          style={{ width: `${percent > 100 ? 100 : percent}%` }}
          data-percent={percent}
        >
          <span className="percent f-14 f-400">
            {percent > 100 ? '100%' : `${percent}%`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InvestProgressBar;
