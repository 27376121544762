import React from 'react';

import './facebook.scss';

function Facebook() {
  return (
    <a
      className="socialButton_facebook"
      href="https://www.facebook.com/ScrambleUpCom"
      target="_blank"
      rel="noreferrer"
    >
      <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.84468 17V8.499H7.00008L7.28571 5.56951H4.84468L4.84834 4.10328C4.84834 3.33922 4.91502 2.92982 5.92299 2.92982H7.27045V0H5.11475C2.52541 0 1.61403 1.42112 1.61403 3.81099V5.56985H0V8.49934H1.61403V17H4.84468Z" fill="black" />
      </svg>
    </a>
  );
}

export default Facebook;
