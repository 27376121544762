import React, { useMemo, useState } from 'react';
import * as classnames from 'classnames';
import { parseWithLinks } from '../../../helpers/utils';
import {ReactComponent as IconArrow} from "../../../assets/images/icon_arrow_right.svg";
import './index.scss';

const SimpleDropdown = ({
  label,
  isActive,
  children,
  innerContentHTML,
  data,
  disableIcon = false,
}) => {
  const [show, setShow] = useState(!!isActive);

  const text = useMemo(
    () => parseWithLinks(innerContentHTML),
    [innerContentHTML]
  );

  const onHandleShowMenu = () => {
    setShow(!show);
  };

  return (
    <div
      className={classnames('simple-dropdown', (isActive || show) && 'active')}
    >
      <div
        className="simple-dropdown__label"
        onClick={onHandleShowMenu}
        id={data.slug || data.id}
      >
        {!disableIcon && <IconArrow />}
        {typeof label === 'function' ? (
          label()
        ) : (
          <span className="f-26-32 f-500">{label}</span>
        )}
      </div>
      <div className="simple-dropdown__content">
        <div className="simple-dropdown__content-inner unreset f-16">
          {innerContentHTML ? text : children}
          <div className="simple-dropdown__content-inner-doubleheight" />
        </div>
      </div>
    </div>
  );
};

export default SimpleDropdown;
