import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import qs from "qs";
import {useLocation, useNavigate} from "react-router-dom";
import PageHelmet from '../../components/PageHelmet';
import config from '../../config';
import {LINKS} from '../../helpers/constants';
import {MyLink} from '../../components/MyLink';
import SimpleStepper from '../../components/SimpleStepper';
import MiniFaqs, {MINIFAQ_SOURCES} from '../../containers/MiniFaqs';
import {getReferralCodeFromCookie, saveReferralCodeToCookie} from "../../helpers/referral";
import './index.scss';

const SEO = {
  title: 'Invite friends │ Scramble',
  description: "Here's your opportunity to invest in high-growth startups on Scramble. Be part of those amazing success stories during this round's batch. Start investing now!",
  image: null
}

const CONS = [
  {
    title: 'Annual return above\n average',
    desc: 'from 12% to 18% per\n annum in euro',
    link: 'https://scrambleup.com/product?step=3'
  },
  {
    title: 'High degree of\n protection',
    desc: 'Three-stage risk\n protection mechanics',
    link: 'https://scrambleup.com/product?step=4'
  },
  {
    title: 'Careful selection\n of companies',
    desc: 'New group of\n companies every month',
    link: 'https://scrambleup.com/how-it-works?step=2'
  }
];

const AffiliateFriendsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {innerWidth} = useSelector(state => state.appStore);
  const [refCode, setRefCode] = useState('');

  useEffect(() => {
    const { ref } = qs.parse(location.search.replace('?', ''));
    if (ref) {
      saveReferralCodeToCookie(ref);
      setRefCode(ref);
    } else {
      const code = getReferralCodeFromCookie();
      if (code) {
        navigate(location.pathname + `?ref=${code}`, { replace: true });
        setRefCode(code);
      }
    }
  }, []);

  return (
    <>
      <PageHelmet
        title={SEO.title}
        description={SEO.description}
        image={SEO.image}
        url={config.baseURL + `/round`}
      />

      <div className="aff_friends_page">
        <div className="aff_friends_page__inner">
          <div className="container">
            <h1 className="aff_friends_page__title f-42-48 f-600">
              Start investing with Scramble!
            </h1>
            <p className="aff_friends_page__desc f-18 f-400">
              To thank you for accepting your friend’s invitation, we’re going to give you both a great reward.
              Create a free account today and get a €5 starting cash bonus!
            </p>

            <MyLink
              href={refCode ? `${LINKS.signUp}?ref=${refCode}` : LINKS.signUp}
              className="aff_friends_page__link scr-button yellow f-18 f-500"
              isExternal
            >
              Start investing and get €5 bonus
            </MyLink>


            <section className="aff_friends_page__how">
              <h2 className="aff_friends_page__how_title f-26-32 f-500">
                How it works ?
              </h2>

              <div className="aff_friends_page__how_inner">
                <SimpleStepper
                  stepNumber={3}
                  vertical={innerWidth < 840}
                  className="aff_friends_page__how_stepper"
                />
                <div className="aff_friends_page__how_options f-18 f-400">
                  <div className="aff_friends_page__how_option">
                    <div>Create an account</div>
                    <div>
                      Sign up today and get a<br /> €5 starting bonus
                    </div>
                  </div>
                  <div className="aff_friends_page__how_option">
                    <div>Invest into innovative businesses</div>
                    <div>Take part in the investment<br /> round. You can invest as little as €10 or as much as you want</div>
                  </div>
                  <div className="aff_friends_page__how_option">
                    <div>Receive monthly repayments</div>
                    <div>Receive your repayments every month</div>
                  </div>
                </div>
              </div>

              <MyLink
                href={refCode ? `${LINKS.signUp}?ref=${refCode}` : LINKS.signUp}
                className="aff_friends_page__link scr-button yellow two f-18 f-500"
                isExternal
              >
                Start investing and get €5 bonus
              </MyLink>
            </section>

            <section className="aff_friends_page__why">
              <h2 className="aff_friends_page__why_title f-26-32 f-500">
                Why Scramble?
              </h2>
              <div className="aff_friends_page__why_circles">
                <div className="aff_friends_page__why_circles_inner">
                  {CONS.map(item => (
                    <MyLink key={item.title} href={item.link} isExternal className="aff_friends_page__why_circle">
                      <span className="aff_friends_page__why_circle_content">
                        <span className="f-18 f-500">{item.title}</span>
                        <span className="f-16">{item.desc}</span>
                      </span>
                    </MyLink>
                  ))}
                </div>
              </div>

              <MyLink
                href={refCode ? `${LINKS.signUp}?ref=${refCode}` : LINKS.signUp}
                className="aff_friends_page__link scr-button yellow three f-18 f-500"
                isExternal
              >
                Start investing and get €5 bonus
              </MyLink>
            </section>

            <MiniFaqs source={MINIFAQ_SOURCES.round}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateFriendsPage;